export default interface POST {
    id: number,
    title: {ua: string, en: string},
    text: {ua: string, en: string},
    active: boolean,
    images: string,
    video: string
}

export function newPost(options?: Partial<POST>): POST {
    const defaults = {
        id: 0,
        title: {ua: '', en: ''},
        text: {ua: '', en: ''},
        active: false,
        images: '',
        video: ''
    }
    return {
        ...defaults,
        ...options,
    }
}