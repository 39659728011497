export default interface LIST {
    permanent: {ua: string, en: string},
    important: {ua: string, en: string}
}

export function newList(options?: Partial<LIST>): LIST {
    const defaults = {
        permanent: {ua: '', en: ''},
        important: {ua: '', en: ''}
    }
    return {
        ...defaults,
        ...options,
    }
}
