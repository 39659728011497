export default interface BANK {
    id: number,
    title: {ua: string, en: string},
    description: {ua: string, en: string},
    bank_link: string,
    bank_number: string,
    active?: boolean,
    closed?: boolean
}

export function newBank(options?: Partial<BANK>): BANK {
    const defaults = {
        id: 0,
        title: {ua: '', en: ''},
        description: {ua: '', en: ''},
        bank_link: '',
        bank_number: ''
    }
    return {
        ...defaults,
        ...options,
    }
}
