
import { defineComponent, PropType } from 'vue'
import PRODUCT, {newProduct} from "@/interfaces/product";
import USER, {newUser} from "@/interfaces/user";
import CONTACTS, {newContact} from "@/interfaces/contacts";
import POST, {newPost} from "@/interfaces/post";
import BANK, {newBank} from "@/interfaces/bank";
import LIST, {newList} from "@/interfaces/list";
import apiService from "@/api/api";
import {sha256} from "js-sha256";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "Form",
  // components: { Select },
  props: {
    show: {type: Boolean, default: false},
    authForm: {type: Boolean, default: false},
    action: {type: String, default: ''},
    tab: {type: String, required: true},
    tabKey: {type: String, default: ''},
    data: {type: Object as PropType<PRODUCT | USER | POST | BANK | LIST> | any, required: true}
  },
  data() {
    const formData: PRODUCT | USER | POST | BANK | LIST | any = {}
    const formImages: any[] = []
    const screenImages: any[] = []
    const formVideo: any = undefined
    const screenVideo = ''
    const statusData: any[] = [
        {value: 0, text: 'Возврату или обмену не подлежит'},{value: 1, text: 'Подлежит возврату'},
      {value: 2, text: 'Подлежит обмену'},{value: 3, text: 'Подлежит возврату и обмену'}
    ]
    const roles = [{name: 'Модератор', value: 2},{name: 'Адмiн', value: 3},{name: 'СуперАдмiн', value: 4}]
    return {
      roles,
      statusData,
      formData,
      formImages,
      screenImages,
      categorySelectOpened: false,
      statusSelectOpened: false,
      roleSelectOpened: false,
      editedImageIndex: -1,
      repeatPass: '',
      lang: 'ua',
      formVideo,
      screenVideo
    }
  },
  mounted() {
    // console.log('FORM MOUTED: ', this.USER)
  },
  watch: {
    show: function() {
      // console.log('show ', this.show, this.newData())
      this.formImages = []
      this.screenImages = []
      this.formVideo = undefined
      this.screenVideo = ''
      this.repeatPass = ''
      if (this.show) {
        this.formData = JSON.parse(JSON.stringify(this.newData()))
      }
      else this.formData = {}
      // console.log('formdata ', this.formData)
    },
    // setTimeout(() => {
    //   this.show = true
    // }, 1000)
  },
  computed: {
    ...mapGetters([
      'USER'
      // ...
    ]),
    title(): string {
      // console.log(this)
      if (this.authForm) return 'Авторизацiя'
      let title = this.action === 'add' ? 'Додати ' : this.action === 'edit' ? 'Редагувати ' : 'Видалити '
      // console.log(this.tab, JSON.stringify(this.formData))
      switch (this.tab) {
        case 'products':
          return title + 'товар' + (this.action !== 'del' ? '' : ` ${this.formData.id}?`)
        case 'users':
          return title + 'користувача' + (this.action !== 'del' ? '' : ` ${this.formData.name}?`)
        case 'posts':
          return title + 'пост' + (this.action !== 'del' ? '' : ` ${this.formData.id}?`)
        case 'list':
          return title + 'список речей'
        default:
          return title + ''
      }
    },
    button(): string {
      if (this.authForm) return 'Вхiд'
      switch (this.action) {
        case 'add':
          return 'Додати'
        case 'edit':
          return 'Зберегти'
        case 'del':
          return 'Видалити'
        default:
          return ''
      }
    }
  },
  methods: {
    actionIs(action: string): boolean {
      return action === this.action
    },
    tabIs(tab: string): boolean {
      return this.tab === tab
    },
    statusText(role?: boolean): string {
      if (!role) {
        if (this.formData.status != null) {
          for (let i = 0; i < this.statusData.length; i++) {
            if (this.statusData[i].value === this.formData.status) {
              return this.statusData[i].text
            }
          }
        }
      } else {
        if (this.formData.role != null) {
          for (let i = 0; i < this.roles.length; i++) {
            if (this.roles[i].value === this.formData.role) {
              return this.roles[i].name
            }
          }
        }
      }
      return 'Статус не установлен'
    },
    getArray(stringValue: string): string[] {
      if (!stringValue) return []
      else return stringValue.split(',')
    },
    formHasImagesToSend(): boolean {
      if (!this.formImages.length) {
        return this.formData.removed_images != null;
      }
      for (let i = 0; i < this.formImages.length; i++) {
        if (this.formImages[i] != null) return true
      }
      return false
    },
    imgSrc(image: string): string {
      if (image.length > 24) return image
      return apiService.getSrc(image, this.tabKey)
    },
    newData():  PRODUCT | USER | CONTACTS | POST | LIST | any {
      // console.log('newdata: ', this.tab)
      switch (this.tab) {
        case 'posts':
          if (this.action === 'add') return newPost()
          this.screenImages = this.data.images.split(',')
          this.formImages[this.screenImages.length - 1] = undefined
          if (this.data.video) {
            this.formVideo = undefined
            this.screenVideo = this.data.video
          }
          return newPost(this.data)
        case 'banks':
          if (this.action === 'add') return newBank()
          this.screenImages = [this.data.image]
          this.formImages = [undefined]
          return newBank(this.data)
        case 'contacts':
          return newContact(this.data)
        case 'list':
          return newList(this.data)
        case 'products':
          if (this.action === 'add') {
            return newProduct()
          } else {
            if (this.data.images) {
              this.screenImages = this.data.images.split(',')
              this.formImages[this.screenImages.length - 1] = undefined
            }
            return newProduct(this.data)
          }
        case 'users':
          if (this.action === 'add') return newUser()
          this.screenImages = [this.data.avatar]
          this.formImages = [undefined]
          return newUser(this.data)
        case 'gallery':
          if (this.data.images !== '') {
            this.screenImages = this.data.images.split(',')
            this.formImages[this.screenImages.length - 1] = undefined
          }
          return this.data
        default:
          return {email: '', password: ''}
      }
    },
    closeForm() {
      this.$emit('close')
    },
    confirmAction() {
      const obj: any = {action: this.action, data: null}
      switch (this.action) {
        case 'add':
          if ((this.tabIs('products') || this.tabIs('banks') || this.tabIs('posts')) && !this.formImages.length) {
            this.$emit('notify', {type: 'w', text: 'Додайте зображення'})
            return
          }
          if (this.tabIs('users')) {
            // console.log('FORMDATA: ', JSON.stringify(this.formData))
            if (this.formData.name === '') {
              this.$emit('notify', {type: 'w', text: 'Додайте iм\'я'})
              return
            }
            if (this.formData.email === '') {
              this.$emit('notify', {type: 'w', text: 'Додайте логiн'})
              return
            }
            if (!this.formData.role || this.formData.role < 2) {
              this.$emit('notify', {type: 'w', text: 'Додайте статус'})
              return
            }
            this.formData.password = sha256.create().update('qqq').hex()
          }
          if (this.tabIs('posts')) {
            if (!this.formData.title.en || !this.formData.title.ua) {
              this.$emit('notify', {type: 'w', text: 'Додайте заголовок'})
              return
            }
            if (!this.formData.text.en || !this.formData.text.ua) {
              this.$emit('notify', {type: 'w', text: 'Додайте текст'})
              return
            }
            this.formData.author = this.USER.id
          }
          if (this.tabIs('banks')) {
            if (!this.formData.title.en || !this.formData.title.ua) {
              this.$emit('notify', {type: 'w', text: 'Додайте заголовок'})
              return
            }
            if (this.formData.bank_link === '') {
              this.$emit('notify', {type: 'w', text: 'Додайте посилання'})
              return
            }
            if (this.formData.bank_number === '') {
              this.$emit('notify', {type: 'w', text: 'Додайте номер'})
              return
            }
          }
          if (this.formData.price != null) this.formData.price = +this.formData.price
          obj.data = this.formData
          if (this.tabIs('products') || this.tabIs('posts')) obj.images = this.formImages
          else obj.image = this.formImages[0]
          if (this.tabKey === 'b') obj.video = this.formVideo
          break
        case 'edit': {
          // alert('EDIT')
          let keys = Object.keys(this.formData)
          if (keys.length === 0) return
          if (this.formData.price != null) this.formData.price = +this.formData.price
          if (this.formData.quantity != null) this.formData.quantity = +this.formData.quantity
          if (this.formData.discount != null) {
            this.formData.discount = +this.formData.discount
            if (this.formData.discount === 0 && this.data.discount > 0) this.formData.discount = -1
          }
          if (this.formData.password && this.formData.password === this.repeatPass) {
            this.formData.password = sha256.create().update(this.formData.password).hex()
          }
          let editedData: any = {}
          console.log('DATA: ', JSON.stringify(this.data), JSON.stringify(this.formData))
          for (let i = 0; i < keys.length; i++) {
            switch (keys[i]) {
              case 'image':
              case 'images':
              case 'avatar':
              case 'active':
              case 'closed':
              case 'video':
              case 'removed_images':
                editedData[keys[i]] = this.formData[keys[i]]
                break
              // case 'important':
              // case 'permanent':
              //   break
              default:
                if (this.data[keys[i]] !== this.formData[keys[i]]) editedData[keys[i]] = this.formData[keys[i]]
            }
            if (i === keys.length - 1) {
              console.log('EDITED DATA: ', editedData)
              let length = Object.keys(editedData).length
              if (length <= 1 && (length === 0 ? true : editedData.images != null || editedData.image != null || editedData.avatar != null) && !this.formHasImagesToSend()) {
                this.$emit('notify', {type: 'w', text: 'Додайте зображення'})
                return
              }
              editedData.id = this.data.id
            }
          }
          obj.data = editedData
          if (this.tabIs('products') || this.tabIs('gallery') || this.tabIs('posts')) {
            obj.images = this.formHasImagesToSend() ? this.formImages : undefined
            if (obj.images) obj.data.images = this.data.images
          } else if (this.tabIs('users')) {
            obj.avatar = this.formHasImagesToSend() ? this.formImages[0] : undefined
            if (obj.avatar) obj.data.avatar = this.data.avatar
          } else {
            obj.image = this.formHasImagesToSend() ? this.formImages[0] : undefined
            if (obj.image) obj.data.image = this.data.image
            if (this.tabKey === 'b') obj.video = this.formVideo
          }
          break
        }
        // case 'del':
        //   obj.data = this.formData
        //   break
        default:
          obj.data = this.formData
      }
      this.$emit('submit', obj)
    },
    clickToEditImage(index: number) {
      this.editedImageIndex = index
      const input: any = this.$refs['img-edit'] as HTMLInputElement
      if (input) input.click()
      else this.editedImageIndex = -1
    },
    showEditInput(): boolean {
      if (this.actionIs('add')) return true
      let maxFiles = 0
      switch (this.tab) {
        case 'posts':
          maxFiles = 2
          break
        case 'products':
          maxFiles = 10
          break
        case 'gallery':
          maxFiles = 200
      }
      if (maxFiles > 0) return maxFiles - this.screenImages.length > 0
      return false
    },
    onFileInput(event: any) {
      if (event && event.target && event.target.files && event.target.files.length) {
        this.$nextTick().then(() => {
          if(this.actionIs('add')) {
            this.formImages = []
            this.screenImages = []
          }
          const files = event.target.files
          let maxFiles = 0
          switch (this.tab) {
            case 'posts':
              maxFiles = 2
              break
            case 'products':
              maxFiles = 10
              break
            case 'gallery':
              maxFiles = 200
          }
          if (maxFiles > 0 && this.actionIs('edit') && this.editedImageIndex === -1) maxFiles = maxFiles - this.screenImages.length
          for (let i = 0; i < files.length; i++) {
            if (maxFiles > 0 && i > maxFiles - 1) break
            let reader = new FileReader()
            reader.onload = (function (index: number, images: Array<string>, actionIs: (action: string) => boolean) {
              return function (e: any) {
                if(actionIs('add') || index === -1) images.push(e.target.result)
                else images.splice(index, 1, e.target.result)
              }
            })(this.editedImageIndex, this.screenImages, this.actionIs);
            if(this.actionIs('add') || this.editedImageIndex === -1) this.formImages.push(event.target.files[i])
            else this.formImages.splice(this.editedImageIndex, 1, event.target.files[i])
            reader.readAsDataURL(event.target.files[i])
          }
        });
      }
    },
    onVideoInput(event: any) {
      this.$nextTick().then(() => {
        if(this.actionIs('add')) {
          this.formVideo = undefined
          this.screenVideo = ''
        }
        const file = event.target.files[0]
        let reader = new FileReader()
        reader.onload = (function (t: any) {
          return function (e: any) {
            t.screenVideo = e.target.result
          }
        })(this)
        this.formVideo = file
        reader.readAsDataURL(file)
      });
    },
    onNumberInput(e: any, checkForDiscount?: boolean) {
      console.log(e.keyCode)
      if (e && e.keyCode && ((e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8 || e.keyCode === 46)) {
        if (checkForDiscount) {
          // console.log(this.formData.discount)
          if (+this.formData.discount <= 100) {
            this.formData.discount = +this.formData.discount
            return
          } else {
            this.formData.discount = 100
          }
        } else {
          this.formData.price = +this.formData.price
          this.formData.quantity = +this.formData.quantity
          return
        }
      }
      // console.log('preventing')
      e.preventDefault()
    },
    onPassInput(e: any) {
      if (e && e.target) this.formData.password = e.target.value
    },
    clickOnSelect(key: string) {
      switch (key) {
        case 'cat':
          this.categorySelectOpened = !this.categorySelectOpened
          if (this.statusSelectOpened) this.statusSelectOpened = false
          if (this.roleSelectOpened) this.roleSelectOpened = false
          break
        case 'status':
          this.statusSelectOpened = !this.statusSelectOpened
          if (this.categorySelectOpened) this.categorySelectOpened = false
          if (this.roleSelectOpened) this.roleSelectOpened = false
          break
        case 'role':
          this.roleSelectOpened = !this.roleSelectOpened
          if (this.categorySelectOpened) this.categorySelectOpened = false
          if (this.statusSelectOpened) this.statusSelectOpened = false
          break
        default:
      }
    },
    onSelect(value: string | number, key?: string) {
      // console.log('on select: ', value, key)
      if (!key) {
        if (this.formData.name != null) {
          value = value + ''
          let arr: string[] = this.formData.name.split(',')
          const index = arr.indexOf(value)
          if (index > -1) {
            arr.splice(index, 1)
            this.formData.name = arr.join(',')
          } else {
            if (this.formData.name !== '') this.formData.name += ','
            this.formData.name += value
          }
        }
      } else {
        // console.log(this.formData[key] != null)
        this.formData[key] = value
        if (key === 'status') this.statusSelectOpened = false
        if (key === 'role') this.roleSelectOpened = false
      }
      // console.log(JSON.stringify(this.formData))
    },
    optionValueChosen(value: string | number, key?: string): boolean {
      if (key != null && key !== '') {
        if (this.formData[key] != null) return +this.formData[key] === +value
        else return false
      } else return this.formData.name.indexOf(value) > -1
    },
    addToPermanent() {
      const input: any = this.$refs['perm-edit']
      if (input) {
        if (this.formData.permanent[this.lang]) this.formData.permanent[this.lang] += ','
        this.formData.permanent[this.lang] += input.value
        input.value = ''
      }
    },
    addToImportants() {
      const input: any = this.$refs['imp-edit']
      if (input) {
        if (this.formData.important[this.lang]) this.formData.important[this.lang] += ','
        this.formData.important[this.lang] += input.value
        input.value = ''
      }
    },
    removeFromImportants(index: number) {
      const array = this.formData.important[this.lang].split(',')
      array.splice(index, 1)
      this.formData.important[this.lang] = array.join(',')
    },
    removeFromPermanents(index: number) {
      const array = this.formData.permanent[this.lang].split(',')
      array.splice(index, 1)
      this.formData.permanent[this.lang] = array.join(',')
    },
    async removeImage(index: number, e: any) {
      console.log('rem img', index, this.formImages)
      e.preventDefault()
      if (index != null && index >= 0) {
        if (this.screenImages[index] == null) return
        const prevScreens = this.formData.images !== '' ? this.formData.images.split(',') : []
        const trueIndex = await (() => {
          return new Promise(resolve => {
            if (this.screenImages[index].length > 25) {
              resolve(index)
              return
            }
            if (!prevScreens.length) resolve(index)
            else {
              for (let i = 0; i < prevScreens.length; i++) {
                if (this.screenImages[index] === prevScreens[i]) {
                  resolve(i)
                  return
                }
                if (i === prevScreens.length - 1) resolve(index)
              }
            }
          })
        })()
        if (!this.formData.removed_images) this.formData.removed_images = []
        if (this.screenImages[index].length <= 24 && this.formData.removed_images.indexOf(trueIndex) < 0) this.formData.removed_images.push(trueIndex)
        this.screenImages.splice(index, 1)
        this.formImages.splice(index, 1)
      }
    },
  }
})
