export default interface PRODUCT {
    id: number,
    name: {ua: string, en: string},
    description: {ua: string, en: string},
    images: string,
    price: number,
    quantity: number,
    active: boolean
}

export function newProduct(options?: Partial<PRODUCT>): PRODUCT {
    const defaults = {
        id: 0,
        name: {ua: '', en: ''},
        description: {ua: '', en: ''},
        images: '',
        price: 0,
        quantity: 0,
        active: false
    }
    return {
        ...defaults,
        ...options,
    }
}
