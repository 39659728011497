export default interface CONTACTS {
    email: string,
    phone: string,
    facebook: string,
    instagram: string,
    tiktok: string
}

export function newContact(options?: Partial<CONTACTS>): CONTACTS {
    const defaults = {
        email: '',
        phone: '',
        facebook: '',
        instagram: '',
        tiktok: ''
    }
    return {
        ...defaults,
        ...options,
    }
}